<template>
  <div v-if="componentLoading">
    <div
      class="bg-black position-fixed"
      style="width: 100%; height: 100%; z-index: 4; opacity: 0.1"
    ></div>

    <div
      class="card p-5 position-fixed"
      style="
        z-index: 10000;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      "
    >
      <img
        class="d-block mx-auto"
        style="width: 20%"
        src="@/assets/loading.gif"
      />
      <h1 style="color: black" class="text-dark text-center my-4">
        {{ componentLoadingMessage }}
      </h1>
    </div>
  </div>
  <AppLayout>
    <main>
      <div class="container-fluid mt-5">
        <div
          class="
            d-flex
            justify-content-between
            align-items-sm-center
            flex-column flex-sm-row
            mb-4
          "
        >
          <div class="mr-4 mb-3 mb-sm-0">
            <h1 class="mb-0">Dashboard</h1>
            <div class="small">
              <span class="font-weight-500 text-primary"></span>
              {{ date.split("G")[0] }}
            </div>
          </div>
          <!-- <div class="dropdown">
            <a
              class="
                btn btn-white btn-sm
                font-weight-500
                line-height-normal
                p-3
                dropdown-toggle
              "
              id="dropdownMenuLink"
              href="#"
              role="button"
              data-toggle="dropdown"
              data-display="static"
              aria-haspopup="true"
              aria-expanded="false"
              ><i class="text-primary mr-2" data-feather="calendar"></i>Jan -
              Feb 2020</a
            >
            <div
              class="dropdown-menu dropdown-menu-sm-right animated--fade-in"
              aria-labelledby="dropdownMenuLink"
            >
              <a class="dropdown-item" href="#!">Last 30 days</a
              ><a class="dropdown-item" href="#!">Last week</a
              ><a class="dropdown-item" href="#!">This year</a
              ><a class="dropdown-item" href="#!">Yesterday</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#!">Custom</a>
            </div>
          </div> -->
        </div>
        <div class="alert alert-primary border-0 mb-4 mt-5 px-md-5">
          <div class="position-relative">
            <div class="row align-items-center justify-content-between">
              <div class="col position-relative">
                <h2 class="text-primary">
                  Welcome back
                  {{ isLoggedIn ? user.broker.split(" ")[0] : "" }}, your
                  {{ user.role == "Admin" ? "admin" : "" }}
                  dashboard is ready!
                </h2>
                <p class="text-gray-700">
                  <!-- Great job, your affiliate dashboard is ready to go! You can
                  view sales, generate links, prepare coupons, and download
                  affiliate reports using this dashboard. -->
                </p>
                <router-link
                  v-if="user.role == 'Broker'"
                  class="btn btn-primary"
                  to="/broker/buymarine"
                  ><span class="text-white">Buy Marine Insurance</span
                  ><i class="ml-1" data-feather="arrow-right"></i
                ></router-link>
                <router-link
                  v-else-if="user.role == 'Admin'"
                  class="btn btn-primary"
                  to="/users/newuser"
                  ><span class="text-white">Set Up New User</span
                  ><i class="ml-1" data-feather="arrow-right"></i
                ></router-link>
              </div>
              <div class="col d-none d-md-block text-right pt-3">
                <img
                  class="img-fluid mt-n5"
                  src="assets/img/drawkit/color/drawkit-content-man-alt.svg"
                  style="max-width: 25rem"
                />
              </div>
            </div>
          </div>
        </div>

        <div v-if="user.role == 'Admin'" class="row">
          <div class="col-lg-12 col-xl-12 mb-4">
            <div class="card mb-4">
              <div class="card-header">All Users</div>
              <div class="card-body">
                <div v-if="pageLoading && users.length == 0">
                  <img
                    style="width: 30%"
                    class="d-block mx-auto"
                    src="@/assets/loader.gif"
                  />
                </div>
                <div v-else-if="users.length == 0">
                  <img
                    style="width: 30%"
                    class="d-block mx-auto"
                    src="@/assets/nodata.svg"
                  />
                  <p class="text-center">
                    No data currently available
                    <router-link to="/users/newuser"
                      >Create New User</router-link
                    >
                    to continue
                  </p>
                </div>
                <div v-else-if="users.length !== 0" class="datatable table-responsive">
                  <table
                    class="table table-bordered table-hover"
                    id="dataTable"
                    cellspacing="0"
                    width="100%"
                  >
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone Number</th>
                        <!-- <th>Company</th> -->
                        <th>Role</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tfoot>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone Number</th>
                        <!-- <th>Company</th> -->
                        <th>Role</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </tfoot>
                    <tbody>
                      <tr v-for="user in users" :key="user.id">
                        <td>{{ user.name }}</td>
                        <td>{{ user.email }}</td>
                        <td>{{ user.phoneNumber }}</td>
                        <!-- <td>{{ user.company }}</td> -->
                        <td>{{ user.role }}</td>
                        <td>
                          <div class="badge badge-primary badge-pill">
                            {{ user.status }}
                          </div>
                        </td>
                        <td>
                          <router-link
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Edit User"
                            class="btn btn-datatable btn-transparent-dark mr-2"
                            :to="{ name: 'EditUser', params: { id: user.id } }"
                          >
                            <i
                              class="bi bi-pencil"
                              style="font-size: 1rem"
                            ></i></router-link
                          ><button
                            data-toggle="tooltip"
                            title="Deactivate"
                            class="
                              btn btn-datatable btn-icon btn-transparent-dark
                            "
                          >
                            <i
                              class="bi bi-x-circle"
                              style="font-size: 1rem"
                            ></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else class="row">
          <div class="col-lg-12 col-xl-12 mb-4">
            <div class="card mb-4">
              <div class="card-header">All Booked Policy Certificates</div>
              <div class="card-body">
                <div v-if="pageLoading && bookedBusinesses.length == 0">
                  <img
                    style="width: 30%"
                    class="d-block mx-auto"
                    src="@/assets/loader.gif"
                  />
                </div>
                <div v-else-if="bookedBusinesses.length == 0">
                  <img
                    style="width: 30%"
                    class="d-block mx-auto"
                    src="@/assets/nodata.svg"
                  />
                  <p class="text-center">
                    No data currently available
                    <router-link to="/broker/buymarine"
                      >Book New Marine Policy</router-link
                    >
                    to continue
                  </p>
                </div>
                <div v-else class="datatable table-responsive">
                  <table
                    class="table table-bordered table-hover"
                    id="dataTable"
                    cellspacing="0"
                    width="100%"
                  >
                    <thead>
                      <tr>
                        <th>Date Booked</th>
                        <th>Insured Name</th>
                        <th>Certificate No</th>
                        <th>Certificate Type</th>
                        <th>Phone Number</th>
                        <th>From</th>
                        <th>To</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tfoot>
                      <tr>
                        <th>Date Booked</th>
                        <th>Insured Name</th>
                        <th>Certificate No</th>
                        <th>Certificate Type</th>
                        <th>Phone Number</th>
                        <th>From</th>
                        <th>To</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </tfoot>
                    <tbody>
                      <tr
                        v-for="business in bookedBusinesses"
                        :key="business.id"
                      >
                        <td>{{ business.submittedOn.split("T")[0] }}</td>
                        <td>{{ business.insuredName }}</td>
                        <td>{{ business.certifateNo }}</td>
                        <td>{{ business.certificateType }}</td>
                        <td>{{ business.phoneNo }}</td>
                        <td>{{ business.from }}</td>
                        <td>{{ business.to }}</td>
                        <td>
                          <span
                            :class="[
                              business.tranrsactionreference !== null &&
                              business.tranrsactionreference !== ''
                                ? 'text-success'
                                : 'text-danger',
                            ]"
                          >
                            {{
                              business.tranrsactionreference !== null &&
                              business.tranrsactionreference !== ""
                                ? "Paid"
                                : "Pending Payment"
                            }}
                          </span>
                        </td>

                        <td>
                          <router-link
                            data-toggle="tooltip"
                            data-placement="top"
                            title="View Business"
                            class="btn btn-datatable btn-transparent-dark mr-2"
                            :to="{
                              name:
                                user.role == 'Broker'
                                  ? 'BookedBusiness'
                                  : 'AgentBookedBusiness',
                              params: { id: business.certifateNo },
                            }"
                          >
                            <i
                              class="bi bi-eye-fill"
                              style="font-size: 1.2rem"
                            ></i
                          ></router-link>

                          <button
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Rebook Business"
                            class="btn btn-datatable btn-transparent-dark mr-2"
                            @click.prevent="getClient(business.policyno)"
                          >
                            <i
                              style="font-size: 1.2rem"
                              class="bi bi-clipboard-plus"
                            ></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </AppLayout>
</template>

<script>
import { useMainStore } from "../stores/main";
import AppLayout from "../layouts/AppLayout.vue";
import { mapState, mapActions } from "pinia";
import { useMarineStore } from "../stores/marine";
import { useAdminStore } from "../stores/admin";
import $ from "jquery";

export default {
  name: "Home",
  components: { AppLayout },
  data() {
    return {
      toggleNav: false,
      date: "",
      pageLoading: false,
    };
  },
  computed: {
    ...mapState(useMainStore, ["user", "isLoggedIn"]),
    ...mapState(useMarineStore, [
      "bookedBusinesses",
      "componentLoading",
      "componentLoadingMessage",
    ]),
    ...mapState(useAdminStore, ["users"]),
  },

  methods: {
    ...mapActions(useMarineStore, [
      "getBookedBusinesses",
      "getAgentBookedBusinesses",
      "getClient",
    ]),
    ...mapActions(useAdminStore, ["getAllUsers"]),
    toggleSidebar() {
      this.toggleNav = !this.toggleNav;
    },
  },

  created() {
    if (this.isLoggedIn) {
      // $(document).ready(function () {
      //   $("#dataTable").DataTable();
      // });
      if (this.user.role == "Admin") {
        this.pageLoading = true;
        this.getAllUsers().then(() => {
          this.pageLoading = false;
          $(document).ready(function () {
            $("#dataTable").DataTable();
          });
        });
      } else if (this.user.role == "Broker") {
        this.pageLoading = true;
        this.getBookedBusinesses().then(() => {
          this.pageLoading = false;
          $(document).ready(function () {
            $("#dataTable").DataTable();
          });
        });
      } else {
        this.pageLoading = true;
        this.getAgentBookedBusinesses().then(() => {
          this.pageLoading = false;
          $(document).ready(function () {
            $("#dataTable").DataTable();
          });
        });
      }
    }

    const d = new Date();
    this.date = d.toString();
  },
};
</script>
